.slide-pane {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s;
  will-change: transform;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}
.slide-pane:focus {
  outline-style: none;
}
.slide-pane_from_right {
  margin-left: auto;
  transform: translateX(100%);
}
.slide-pane_from_right.content-after-open {
  transform: translateX(0%);
}
.slide-pane_from_right.content-before-close {
  transform: translateX(100%);
}
.slide-pane_from_left {
  margin-right: auto;
  transform: translateX(-100%);
}
.slide-pane_from_left.content-after-open {
  transform: translateX(0%);
}
.slide-pane_from_left.content-before-close {
  transform: translateX(-100%);
}
.slide-pane_from_bottom {
  height: 100vh;
  transform: translateY(100%);
  margin-left: auto;
  margin-right: auto;
}
.slide-pane_from_bottom.content-after-open {
  transform: translateY(0%);
}
.slide-pane_from_bottom.content-before-close {
  transform: translateY(100%);
}
.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.slide-pane__overlay.overlay-after-open {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s;
}
.slide-pane__overlay.overlay-before-close {
  background-color: rgba(0, 0, 0, 0);
}
.slide-pane__header {
  display: flex;
  align-items: center;
  background: #0a0a0a;
  height: 64px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}
.slide-pane__title-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-left: 32px;
  min-width: 0;
}
.slide-pane .slide-pane__title {
  font-size: 18px;
  font-weight: normal;
  max-width: 90%;
  max-height: 80%;
  text-align: left;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.slide-pane__close {
  padding-right: 16px;
  opacity: 0.7;
  cursor: pointer;
}
.slide-pane__close svg {
  width: 12px;
  padding: 0;
}
.slide-pane__content {
  position: relative;
  overflow-y: auto;
  flex: 1 1 auto;
}
